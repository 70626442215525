import _styled from "styled-components";
import { useState } from 'react';
import { ButtonType, Size, Icon, Button, TextIconColor } from '~/components/core';
import { useOrgSettings } from '~/hooks';
import { nextTrpc } from '~/trpc';
const RunCodeButtonContainer = _styled.div({
  "display": "flex",
  "gap": "0.5rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const RunCodeButton: React.FC<{
  disabled: boolean;
  chatMessageId?: string | undefined;
  codeIndex?: number | undefined;
  textIconColor?: TextIconColor;
}> = ({
  chatMessageId,
  codeIndex,
  disabled,
  textIconColor
}) => {
  const {
    orgSettings
  } = useOrgSettings();
  const showButton = chatMessageId && codeIndex !== undefined && orgSettings?.codeSandboxEnabled;
  const [isRunning, setIsRunning] = useState(false);
  const executeCodeMutation = nextTrpc.chatMessage.executeCode.useMutation({
    onMutate: () => {
      setIsRunning(true);
    },
    onSettled: () => {
      setIsRunning(false);
    }
  });
  if (!showButton) {
    return null;
  }
  return <RunCodeButtonContainer>
      <Button disabled={disabled || isRunning} endIcon={Icon.PLAY} label="Run code" onClick={() => {
      executeCodeMutation.mutateAsync({
        messageId: chatMessageId,
        codeIndex
      });
    }} size={Size.SMALL} textIconColor={textIconColor} type={ButtonType.TEXT} />
    </RunCodeButtonContainer>;
};
export default RunCodeButton;